import logo from '../images/logo.png';
import './Home.css'

function Home() {
    return (
    <div className='page-container-div'>
        <div className='Home'>
            <h1> Welcome to SIGPolicy! </h1>
            <img width={250} height={225} src={logo} alt=""/>
            <p> 
                A special interest group for cyber policy and ethics in ACM@UIUC.
            </p>
        </div>
    </div>
    );
  }

export default Home;