import './Resources.css'

function Tab({href, tag}) {
    return (
        <a target="_blank" rel="noreferrer" href={href}>
            {tag}
        </a>
    )
}
function Courses() {
    return (
        <div className="Courses">
            <h2> Courses </h2>
            <ul>
                <li><Tab href={'https://cs.illinois.edu/academics/courses/cs498rc1'}
                         tag={'CS 498: Law & Policy Issues in CS'}/></li>
                <li><Tab href={'https://cs.illinois.edu/academics/courses/cs464'}
                        tag={'CS 464: Topics in Societal Impacts'}/></li>
                <li><Tab href={'https://publish.illinois.edu/ecephil316/'}
                        tag={'ECE 316: Ethics and Engineering'}/></li>
                <li><Tab href={'https://cs.illinois.edu/academics/courses/cs210'}
                        tag={'CS 210: Ethics and Professional Issues in CS'}/></li>
            </ul>
        </div>
    );
}

function Books() {
    return (
        <div className="Books">
            <h2> Textbooks </h2>
            <ul>
                <li><Tab href={'https://internetcasebook.com/'}
                        tag={'Internet Law: Cases and Problems'}/></li>
                <li><Tab href={'https://faculty.westacademic.com/Book/Detail/334847'}
                        tag={'Computer Crime Law'}/></li>
                <li><Tab href={'https://commons.wikimedia.org/wiki/File:Code_v2.pdf'}
                        tag={'Code Version 2.0'}/></li>
            </ul>
        </div>
    );
}

function Organizations() {
    return (
        <div className="Organizations">
            <h2> Organizations </h2>
            <ul>
                <li><Tab href={'https://computersciencelaw.org/'}
                        tag={"ACM CS and Law Symposium"}/></li>
                <li><Tab href={'https://www.sigcas.org/'}
                        tag={'ACM SIGCAS'}/></li>
                <li><Tab href={'https://dfrlab.org/policy/cyber-statecraft-initiative/'}
                        tag="The Atlantic Council's Cyber Statecraft Initiative"/></li>
                <li><Tab href={'https://law.yale.edu/isp'}
                        tag="Yale's Information Society Project"/></li>
                <li><Tab href={'https://cyber.fsi.stanford.edu/sciprojects'}
                        tag={"Stanford's Cyber Policy Center"}/></li>
                <li><Tab href={'https://internetpolicy.mit.edu/'}
                        tag="MIT's Internet Policy Research Initiative"/></li>
                  <li><Tab href={'https://www.adalovelaceinstitute.org/'}
                        tag={'The Ada Lovelace Institute'}/></li>
            </ul>
        </div>
    );
}

function Resources() {
    return (
        <div className="Resources">
            <div className="page-container-div">
                <Courses/>
            </div>

            <div className="page-container-div">
                <Books/>
            </div>

            <div className='page-container-div'>
                <Organizations/>
            </div>
    </div>
    )
}

export default Resources;