import './About.css'
import binary_logo from '../images/binary_logo.png';

function About() {
    return (
      <div className='page-container-div'>
        <img width={300} height={225} src={binary_logo} alt=""/>
        <div className="About">
          <p> Established 2021. We analyze policy vacuums and conceptual muddles.
            </p>
          <h2> Goals </h2> 
          <ol type="1">
            <li> To form community. </li>
            <li> To build techno-legal literacy. </li>
          </ol>
          <h2> Methods </h2>
          <ul type="1">
            <li> Lectures, discussions, and group debates. </li>
            <li> Socratic case analysis. </li>
            <li> Candy catch. </li>
          </ul>
      </div>
    </div>
    );
}

export default About;