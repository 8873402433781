import './Contact.css'
import orange_computer from '../images/sigpolicy_mascot.png';

function Contact() {
  // TODO: make sure screen readers make it obvious that image is a link to Discord
    return (
      <div className="Contact">
        <div className='page-container-div'>
          <a target="_blank" rel="noreferrer" href={'https://discord.gg/cj8jxJDTWr'}>
            <img className='mascot-image' width={300} height={250} src={orange_computer} alt="Scales of justice, balancing a computer and bag of money on one side with a stack of paper on the other side, smiling, wearing a cowboy hat, surrounded by candy at its feet"/>
            </a>
          <p> Click ↑ to join our Discord. </p>
          <p> Open to mascot naming suggestions. </p>
      </div>
    </div>
    );
}

export default Contact;