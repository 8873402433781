import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import App from './App';
import About from './About/About';
import Contact from './Contact/Contact';
import Home from './Home/Home'
import Meetings from './Meetings/Meetings';
import Resources from './Resources/Resources';

// TODO: do we want to switch back to createBrowserRouter by using something like
// https://github.com/rafgraph/spa-github-pages for all the reasons that 
// https://reactrouter.com/en/main/routers/picking-a-router#web-projects mentions?
const router = createHashRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "about",
        element: <About/>
      },
      {
        path: 'meetings',
        element: <Meetings/>
      },
      {
        path: 'contact',
        element: <Contact/>
      },
      {
        path: 'resources',
        element: <Resources/>
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
