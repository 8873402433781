import './App.css';

import { Outlet, NavLink } from "react-router-dom";
import hammer_logo from './images/hammer_logo.png';

function LinkSelector() {
  return (
    <nav>
        <ul>
          <li> 
            <NavLink to={'/about'} activeClassName="active"> About </NavLink> 
          </li>
          <li> 
            <NavLink to={'/meetings'} activeClassName="active"> Meetings </NavLink>
          </li>
          <li>
            <NavLink to={'/resources'} activeClassName="active"> Resources </NavLink>
          </li>
          <li>
            <NavLink to={'/contact'} activeClassName="active"> Contact </NavLink>
          </li>
        </ul>
      </nav>
  );
}

function TopBar() {
  return (
    <div className='top-bar'>
      <div className='hammer-logo-div'>
        <NavLink to={'/'} activeClassName="logo-link">
          <img width={30} height={60} src={hammer_logo} alt="Go to home page"/>
        </NavLink>
      </div>
      <LinkSelector/>
      </div>
  );
}

function App() {
  return (
    <div className='App'>
      <TopBar/>
      <Outlet/>
    </div>
  );
}

export default App;
