import './Meetings.css'
import orange_computer from '../images/orange_computer.png';
import algorithm_bias from '../pdf/algorithm_bias.pdf'
import antitrust_slides from '../pdf/antitrust_slides.pdf'
import copyright_slides from '../pdf/copyright_slides.pdf'
import cfaa_slides from '../pdf/cfaa.pdf'
import section_230_slides from '../pdf/Section 230.pdf'

function Tab({href, tag}) {
    return (
        <a target="_blank" rel="noreferrer" href={href}>
            {tag}
        </a>
    )
}

function Meetings() {
    // Thursdays at 6 PM
    // Siebel 1302
    return (
        <>
        <div className='page-container-div'>
            <div className="Meetings">
                <img width={240} height={175} src={orange_computer} alt=""/>
                <h1> Spring 2024 </h1>
                <h2> Time: 7-8 PM </h2> 
                <h2> Location: Everitt 3117 </h2>
            </div>
        </div>
        <div className='page-container-div'>
            <div className="Past-Meetings">
                <h2> Past Meetings (select)</h2>
                <ul>
                    <li><Tab href={antitrust_slides} 
                            tag={'FA 23 Antitrust'}/></li>
                    <li><Tab href={copyright_slides} 
                        tag={'FA 23 Copyright'}/></li>
                    <li><Tab href={cfaa_slides} 
                            tag={'SP 23 CFAA'}/></li>
                    <li><Tab href={section_230_slides} 
                            tag={'FA 22 Section 230'}/></li>
                    <li><Tab href={algorithm_bias} 
                            tag={'SP 22 Algorithm Bias'}/></li>
                </ul>
            </div>
        </div>
    </>
    );
}

export default Meetings;